<!--
 * @Author: Lmi
 * @Description: 隐患排查台账复检表新增
-->
<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm0" class="el-form-wrap">
        <ykc-form-item label="电站名称" prop="stationId">
          <ykc-dropdown
            placeholder="请选择电站"
            :remote="true"
            :data="stationData"
            @change="selectStationData"
            v-model="ruleForm.stationId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="隐患" prop="danger">
          <ykc-input
            placeholder="请输入隐患内容"
            maxlength="500"
            v-model="ruleForm.danger"
            type="textarea"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="责任人" prop="responsiblePerson">
          <ykc-dropdown
            placeholder="请选择责任人"
            :remote="true"
            :data="responsiblePersonData"
            @change="selectPersonData"
            v-model="ruleForm.responsiblePerson"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="检查人" prop="inspectPerson">
          <ykc-input
            placeholder="请输入检查人"
            maxlength="50"
            v-model="ruleForm.inspectPerson"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="初检时间" prop="initialInspectDate">
          <ykc-date-picker
            v-model="ruleForm.initialInspectDate"
            :format="'yyyy-MM-dd'"
            :valueFormat="'yyyy-MM-dd'"
            :type="'datetime'"
            placeholder="请选择初检时间"
            :onlyHour="true"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="截止整改时间" prop="endRectifyDate">
          <ykc-date-picker
            v-model="ruleForm.endRectifyDate"
            :format="'yyyy-MM-dd'"
            :valueFormat="'yyyy-MM-dd'"
            :type="'datetime'"
            placeholder="请选择截止整改时间"
            :onlyHour="true"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="整改情況" prop="rectifyFlag">
          <ykc-radio
            :data="[
              { id: '1', name: '已整改' },
              { id: '0', name: '未整改' },
            ]"
            v-model="ruleForm.rectifyFlag"
            @change="changerectifyFlag"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="复查情況" prop="reviewFlag">
          <ykc-radio
            :data="[
              { id: '1', name: '已完成' },
              { id: '0', name: '未完成' },
            ]"
            v-model="ruleForm.reviewFlag"
            @change="changereviewFlag"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="复查人" prop="reviewPerson">
          <ykc-input
            placeholder="请输入复查人"
            maxlength="50"
            v-model="ruleForm.reviewPerson"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="完成时间" prop="finishDate">
          <ykc-date-picker
            v-model="ruleForm.finishDate"
            :format="'yyyy-MM-dd'"
            :valueFormat="'yyyy-MM-dd'"
            :type="'datetime'"
            placeholder="请选择完成时间"
            :onlyHour="true"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="备注" prop="remark">
          <ykc-input
            placeholder="请输入备注信息"
            maxlength="200"
            v-model="ruleForm.remark"
            type="textarea"></ykc-input>
        </ykc-form-item>

        <ykc-form-item
          prop="img"
          label="附件上传"
          class="file-error"
          bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过3M">
          <div class="flex upload-img-box">
            <ykc-upload
              ref="pictureUrls1"
              :size="3"
              @handleChange="uploadImgs1"
              @handleRemove="handleRemoveImgs1"></ykc-upload>
            <ykc-upload
              ref="pictureUrls2"
              :size="3"
              @handleChange="uploadImgs1"
              @handleRemove="handleRemoveImgs2"></ykc-upload>
            <ykc-upload
              ref="pictureUrls3"
              :size="3"
              @handleChange="uploadImgs1"
              @handleRemove="handleRemoveImgs3"></ykc-upload>
          </div>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { omManagementApi, barrierPage, uploadUtils } from '@/service/apis';

  export default {
    name: 'AddOrEdit',
    data() {
      return {
        stationData: [], // 电站数据
        responsiblePersonData: [], // 责任人数据
        selectId: null,
        ruleForm: {
          stationName: '',
          stationId: null,
          danger: '', // 隐患
          responsiblePerson: '', // 责任人
          inspectPerson: '', // 检查人
          rectifyFlag: '1', // 整改情況
          reviewFlag: '1', // 复查情況
          reviewPerson: '', // 复查人
          initialInspectDate: '', // 初检时间
          endRectifyDate: '', // 截止整改时间
          finishDate: '', // 完成时间
          remark: '',
          filePath: [], // 图片
        },
        rules: {
          stationId: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          danger: [{ required: true, message: '请输入隐患内容', trigger: 'blur' }],
          responsiblePerson: [{ required: true, message: '请输入责任人', trigger: 'blur' }],
          inspectPerson: [{ required: true, message: '请输入检查人', trigger: 'blur' }],
          initialInspectDate: [{ required: true, trigger: 'change', message: '请选择初检时间' }],
          endRectifyDate: [{ required: true, trigger: 'change', message: '请选择截止整改时间' }],
          rectifyFlag: [{ required: true, message: '请选择整改情況', trigger: 'change' }],
          reviewFlag: [{ required: true, message: '请选择复查情況', trigger: 'change' }],
          reviewPerson: [{ required: true, message: '请输入复查人', trigger: 'blur' }],
          finishDate: [{ required: true, trigger: 'change', message: '请选择完成' }],
        },
      };
    },
    created() {
      this.getStationData();
    },
    computed: {
      formData() {
        const formData = {
          stationName: this.ruleForm.stationName,
          stationId: this.ruleForm.stationId,
          danger: this.ruleForm.danger,
          responsiblePerson: this.ruleForm.responsiblePerson,
          inspectPerson: this.ruleForm.inspectPerson,
          reviewPerson: this.ruleForm.reviewPerson,
          rectifyFlag: this.ruleForm.rectifyFlag,
          reviewFlag: this.ruleForm.reviewFlag,
          initialInspectDate: this.ruleForm.initialInspectDate,
          endRectifyDate: this.ruleForm.endRectifyDate,
          finishDate: this.ruleForm.finishDate,
          remark: this.ruleForm.remark,
          filePath: this.ruleForm.filePath,
        };

        return formData;
      },
    },
    methods: {
      /**
       * @desc 获取电站数据
       * */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = (res || []).map(item => {
            return {
              id: item.stationId,
              name: item.stationName,
            };
          });
        });
      },
      selectStationData(evt) {
        console.log('evt', evt);
        this.ruleForm.stationName = this.stationData.filter(item => {
          return evt === item.id;
        })[0].name;
        this.selectId = evt;
        this.getResponsiblePersonData();
      },
      /**
       * @desc 获取责任人数据
       * */
      getResponsiblePersonData() {
        omManagementApi.queryResponsible({ stationId: this.selectId }).then(res => {
          this.responsiblePersonData = (res || []).map((item, i) => {
            return {
              id: i,
              name: item,
            };
          });
          console.log('----', res);
        });
      },
      selectPersonData(e) {
        console.log('---e', e);
        console.log(('---this.responsiblePersonData', this.responsiblePersonData));
        this.ruleForm.responsiblePerson = this.responsiblePersonData.filter((item, i) => {
          return e === i;
        })[0].name;
        console.log('this.ruleForm.responsiblePerson', this.ruleForm.responsiblePerson);
      },
      /**
       * 切換整改情況
       */
      changerectifyFlag(e) {
        console.log(JSON.stringify(e));
      },
      /**
       * 切換復查情況
       */
      changereviewFlag(e) {
        console.log(JSON.stringify(e));
      },
      /**
       * @desc  图片上传
       */
      uploadImgs1(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.filePath.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      /**
       * @desc 图片移除
       * */
      handleRemoveImgs1(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.filePath.splice(0, 1);
      },
      handleRemoveImgs2(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.filePath.splice(1, 1);
      },
      handleRemoveImgs3(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.filePath.splice(2, 1);
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.validateAllForm()
            .then(() => {
              omManagementApi
                .dangerCheckAdd(this.formData)
                .then(res => {
                  console.log('res++++新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * @desc 校验全部表格是否通过
       * */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log('err++++', err);
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    > div {
      margin-right: 20px;
    }
  }
</style>
